
.experience-accord {
  margin: 50px;
  margin-top: 450px;
  margin-bottom: 150px;
}

.accord-panel {
  font-family: "Google Sans Regular";
}

@media (max-width: 768px) {
  .experience-accord {
    margin: 20px;
  }
}
