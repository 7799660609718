.main-button {
  background-color: #55198b;
  border: solid 1px #55198b;
  color: white;
  font-weight: 500;
  line-height: 1.1;
  width: max-content;
  padding: 13px 22px;
  margin-right: 0px;
  /* text-transform: uppercase; */
  border-radius: 6px;
  text-align: center;
  text-decoration: none;
  font-family: "Google Sans Regular";
  display: block;
  margin-top: 20px;
  font-size: 18px;
  cursor: pointer;
  letter-spacing: 2px;
}
.main-button:hover {
  /* background-color: #ffffff; */
  /* color: black; */
  transition: ease-in 0.3s;
}
.project-button {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}
/* Media Query */
@media (max-width: 768px) {
  .main-button {
    font-size: 15px;
    padding: 12px 18px;
    margin-right: 0px;
  }
}
@media (max-width: 320px) {
  .main-button {
    font-size: 12px;
  }
}

.mai5n-button {
  background-color: #001C55;
  border: solid 1px;
  color: white;
  font-weight: 500;
  line-height: 1.1;
  width: max-content;
  padding: 12px 30px;
  margin-right: 0px;
  /* text-transform: uppercase; */
  border-radius: 6px;
  text-align: center;
  text-decoration: none;
  font-family: "Google Sans Regular";
  display: block;
  margin-top: 50px;
  font-size: 18px;
  cursor: pointer;
  letter-spacing: 2px;
}

.mai5n-button:hover {
  background-color: #0056b3; /* Darker shade for hover */
  
}