
.footer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #1C273B;
  color: #fff;
  padding: 10px;
  width: 100%;
  box-sizing: border-box;
}

.footer-content {
  flex: 1;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.footer-left,
.footer-right {
  flex: 1;
  min-width: 250px;
}

.footer-logo {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px auto; /* Center horizontally and add top/bottom margin */
  width: 100px;
}

.footer-nav {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 10px auto; /* Center horizontally and add top/bottom margin */
  width: 100px;
}
.footer-nav-container {
  display: flex;
  gap: 45px; /* Adjust the spacing between the two nav lists */
  max-width: 800px; /* Set a maximum width for the container */
  width: 100%; /* Ensure the container takes the full width */
  justify-content: space-between; /* Distribute space between the nav lists */
  /* margin-left: auto; */
}

/* .footer-nav.right-nav {
  margin-left: auto;
} */

.footer-nav a {
  margin: 5px 0;
  text-decoration: none;
  color: #fff;
  text-align: left;
  width: 100%;
  max-width: 200px;
  padding-right: 10px;
}

.footer-nav a:hover {
  text-decoration: underline;
}

.footer-social {
  margin-top: 10px;
  text-align: center; /* Center the text */
  margin-bottom: 50px;
  display: flex;
  justify-content: center; /* Center the icons horizontally */
  gap: 45px; /* Adjust the spacing between icons */
}

.social-icon {
  color: #fff; /* Optional: Set the icon color */
  text-decoration: none;
}

.social-icon:hover {
  text-decoration: underline;
}

.subscribe-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.subscribe-form input[type="email"] {
  padding: 10px;
  margin-bottom: 13px; /* Adjust bottom margin */
  border: 1px solid #ccc;
  border-radius: 5px;
  width: 80%; /* Adjust width as needed */
  max-width: 400px; /* Optional: Set a max-width */
  box-sizing: border-box;
}

.subscribe-form button {
  padding: 10px 20px;
  border: none;
  background-color: #2ECC71;
  color: #fff;
  border-radius: 5px;
  cursor: pointer;
  width: 80%; /* Adjust width as needed */
  max-width: 150px; /* Optional: Set a max-width */
  box-sizing: border-box;
}

.subscribe-form button:hover {
  background-color: #00b339;
}

.footer-legal {
  text-align: center;
  padding: 10px 0;
  border-top: 1px solid #444;
  color: #989898;
  font-size: 13px;
}

.footer-legal a {
  color: #989898;
  margin: 0 10px;
  text-decoration: none;
  font-size: 13px
}


.footer-legal a:hover {
  text-decoration: underline;
}
.footer-left h3 {
  line-height: 1.5;
  margin-bottom: 20px;
}
.footer-text {
  text-align: center; /* Center the text */
  margin-top: 10px; /* Adjust top margin */
  margin-bottom: 0px; /* Adjust bottom margin */
}

.footer-right p {
  line-height: 1.2;
  margin-top: 115px;
  margin-bottom: 25px;
  text-align: center;
  font-size: 35px;
}

.footer-right {
  margin-left: auto;
  padding-left: px;
  margin-bottom: 30px;
}
@media (max-width: 768px) {
  .footer-nav-container {
    display: none;
  }
  .subscribe-form button {
    max-width: 400px; /* Optional: Set a max-width */
    margin-bottom: 30px;
}
.footer-right p {
  margin-top: 40px;
  font-size: 20px
}
}

